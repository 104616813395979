"use client"

import { useRouter, usePathname } from "next/navigation"

const NotFound = () => {
  const pathName = usePathname()
  const { replace } = useRouter()
  const isOldSignup = pathName.includes("/signup/") && pathName.split("/").length > 3

  if (isOldSignup) {
    return replace(`/signup/cohort/?cohort=${pathName.split("/")[2]}`)
  }

  return replace("/")
}

export default NotFound
